var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"space-configurations-selector form-input"},[_c('div',{staticClass:"configuration-label"},[(_vm.required)?_c('span',{staticClass:"required"},[_vm._v("*")]):_vm._e(),_c('span',[_vm._v(_vm._s(_vm.label))])]),(_vm.error)?_c('div',{staticClass:"error"},[_vm._v(" "+_vm._s(_vm.error)+" ")]):_vm._e(),_vm._l((_vm.configurations),function(configuration,name){return _c('div',{key:name,staticClass:"space-configurations-item",class:{ checked: configuration.checked },attrs:{"id":("check-" + (configuration.configuration_type)).toLowerCase()}},[_c('div',{staticClass:"top"},[_c('div',{staticClass:"left"},[(_vm.$mq && _vm.$mq.desktop)?_c('div',{staticClass:"configuration_helper"},[_c('i',{class:("icon-config_" + (configuration.configuration_type.toLowerCase()))})]):_vm._e(),_c('FormCheckbox',{on:{"click":function($event){return _vm.onCheck(configuration)}},model:{value:(configuration.checked),callback:function ($$v) {_vm.$set(configuration, "checked", $$v)},expression:"configuration.checked"}},[_vm._v(" "+_vm._s(_vm.$t( ("components.space_configurations_selector.items." + (_vm.SPACE_CONFIGURATIONS[configuration.configuration_type]) + ".label_checkbox") ))+" ")]),_c('div',{staticClass:"description"},[_vm._v(" "+_vm._s(_vm.$t( ("components.space_configurations_selector.items." + (_vm.SPACE_CONFIGURATIONS[configuration.configuration_type]) + ".label_description") ))+" ")])],1)]),(configuration.checked)?_c('div',{staticClass:"bottom"},[_c('div',{staticClass:"switcher",class:{ disabled: _vm.configurationsDisabled }},[_c('Toggle',{attrs:{"value":configuration.custom,"disabled":_vm.configurationsDisabled},on:{"input":function () { return configuration.custom
                ? _vm.onMakeDefault(configuration)
                : _vm.onMakeCustom(configuration); }}},[_c('Option',{attrs:{"label":_vm.$t(
                'components.space_configurations_selector.default_switcher.default'
              ).toString(),"value":false}}),_c('Option',{attrs:{"label":_vm.$t(
                'components.space_configurations_selector.default_switcher.on_request'
              ).toString(),"value":true}})],1)],1),_c('FormIncrementedInput',{staticClass:"attendees",attrs:{"required":"","min-value":1,"max-value":999,"max-length":3,"input-number-min-zero":"","items-name":_vm.$t(
            'components.space_configurations_selector.input_placeholder_attendees'
          ).toString(),"placeholder":_vm.$t(
            'components.space_configurations_selector.input_placeholder_attendees_empty'
          ).toString()},model:{value:(configuration.attendees),callback:function ($$v) {_vm.$set(configuration, "attendees", $$v)},expression:"configuration.attendees"}}),(configuration.custom)?_c('PriceInput',{attrs:{"placeholder":0,"advertised":"","max":999.99},model:{value:(configuration.price),callback:function ($$v) {_vm.$set(configuration, "price", $$v)},expression:"configuration.price"}}):_vm._e()],1):_vm._e()])})],2)}
var staticRenderFns = []

export { render, staticRenderFns }