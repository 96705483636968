import { render, staticRenderFns } from "./SpaceConfigurationsSelector.vue?vue&type=template&id=479866e7&scoped=true&"
import script from "./SpaceConfigurationsSelector.vue?vue&type=script&lang=ts&"
export * from "./SpaceConfigurationsSelector.vue?vue&type=script&lang=ts&"
import style0 from "./SpaceConfigurationsSelector.vue?vue&type=style&index=0&id=479866e7&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "479866e7",
  null
  
)

export default component.exports