import BaseForm from '@/shared/models/BaseForm';
class SpaceConfigurationsModel extends BaseForm {
    configurations;
    id;
    size;
    constructor() {
        super();
        this.id = '';
        this.size = '';
        this.configurations = [];
    }
}
export default SpaceConfigurationsModel;
