import Vue from 'vue';
import SpaceConfigurationsModel from '@/shared/models/space/SpaceConfigurationsModel';
class SpaceConfigurationsForm extends SpaceConfigurationsModel {
    requiredFields() {
        return ['size', 'configurations'];
    }
    _configurationsRequired(vue, form, errorCode) {
        const hasCheckedConfiguration = form.configurations.find(configuration => configuration.checked);
        if (!hasCheckedConfiguration) {
            Vue.set(form._errors, 'configurations', vue.$t(errorCode));
        }
    }
    _attendeesValid(vue, form, errorCode) {
        const hasCheckedConfiguration = form.configurations.find(configuration => configuration.checked);
        const hasInvalidAttendees = form.configurations.find(configuration => configuration.checked && !configuration.attendees);
        if (hasCheckedConfiguration && hasInvalidAttendees) {
            Vue.set(form._errors, 'configurations', vue.$t(errorCode));
        }
    }
    validationRules() {
        return {
            size: [
                {
                    validation: 'required',
                    i18code: 'space.configurations.errors.size_empty'
                },
                {
                    validation: 'maxInt',
                    value: 9999,
                    i18code: 'space.configurations.errors.size_too_long'
                }
            ],
            configurations: [
                {
                    validation: this._configurationsRequired,
                    i18code: 'space.details.errors.configurations_empty'
                },
                {
                    validation: this._attendeesValid,
                    i18code: 'space.details.errors.attendees_invalid'
                }
            ]
        };
    }
}
export default SpaceConfigurationsForm;
